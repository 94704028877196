import { Controller } from '@hotwired/stimulus'
import { isEqual } from 'lodash';

export default class extends Controller {
  static values = {
    initDatasets: Array,
    datasets: Array,
    lockedDatasets: Array,
    maxDatasets: { type: Number, default: 4 }
  }

  static targets = ['datasetsList', 'datasetTemplate', 'compareButton', 'clearListButton', 'lockedDatasetsList', 'lockedDatasetTemplate', 'lockedDatasetMessage']

  datasetsValueChanged() {
    this.renderDatasetsList()
    this.updateUI()
    this.notifyForDatasetsLimit()
  }

  lockedDatasetsValueChanged() {
    this.renderLockedDatasetsList()
    this.notifyForDatasetsLimit()
  }

  renderDatasetsList() {
    this.datasetsListTarget.innerHTML = ''

    if (this.datasetsValue.length === 0) {
      this.datasetsListTarget.innerHTML = 'Your list is empty'
      this.hideButtons()
      return
    }

    this.datasetsValue.forEach((dataset, index) => {
      this.datasetsListTarget.appendChild(
        this.createDatasetElement(dataset, this.datasetTemplateTarget, index)
      )
    })
  }

  renderLockedDatasetsList() {
    this.lockedDatasetsListTarget.innerHTML = ''
    this.lockedDatasetMessageTarget.classList.toggle('hidden', this.lockedDatasetsValue.length === 0)

    this.lockedDatasetsValue.forEach((dataset) => {
      this.lockedDatasetsListTarget.appendChild(
        this.createDatasetElement(dataset, this.lockedDatasetTemplateTarget)
      )
    })
  }

  createDatasetElement(dataset, templateTarget, index = null) {
    let templateContent = templateTarget.innerHTML

    if (index !== null) {
      templateContent = templateContent.replace(/{{datasetOrder}}/g, index + 1)
    }

    templateContent = templateContent.replace(/{{datasetTitle}}/g, dataset.title)
    templateContent = templateContent.replace(/{{dataProvider}}/g, dataset.data_provider_name)
    templateContent = templateContent.replace(/{{datasetId}}/g, dataset.id)

    const element = document.createElement('div')
    element.innerHTML = templateContent
    return element
  }

  removeDatasetFromList(event) {
    event.preventDefault()
    const dataset = this.findDataset(event.params.datasetId)
    if (dataset) this.removeDataset(dataset)
  }

  findDataset(id) {
    return this.datasetsValue.find((d) => d.id === id)
           || this.lockedDatasetsValue.find((d) => d.id === id)
  }

  removeDataset(dataset) {
    this.dispatch('datasetRemoved', { detail: { dataset } })

    const targetArray = dataset.locked ? 'lockedDatasetsValue' : 'datasetsValue'
    this[targetArray] = this[targetArray].filter((d) => d.id !== dataset.id)
  }

  addDataset(event) {
    const { dataset } = event.detail
    if (!dataset || this.isAtMaxCapacity()) return

    const targetArray = dataset.locked ? 'lockedDatasetsValue' : 'datasetsValue'
    this[targetArray] = [...this[targetArray], dataset]
  }

  clearDatasets(event) {
    event.preventDefault();
    [...this.datasetsValue, ...this.lockedDatasetsValue].forEach((dataset) => {
      this.removeDataset(dataset)
    })
  }

  updateUI() {
    if (this.datasetsValue.length > 0) {
      this.showButtons()
      this.updateCompareButton()
    }
  }

  hideButtons() {
    this.compareButtonTarget.classList.add('hidden')
    this.clearListButtonTarget.classList.add('hidden')
  }

  showButtons() {
    this.compareButtonTarget.classList.remove('hidden')
    this.clearListButtonTarget.classList.remove('hidden')
  }

  updateCompareButton() {
    const count = this.datasetsValue.length
    const text = count === 1 ? 'Compare' : `Compare ${count} datasets`
    this.compareButtonTarget.querySelector('span').textContent = text
    this.compareButtonTarget.classList.toggle('disabled', this.datasetsUnchanged())
  }

  datasetsUnchanged() {
    return isEqual(this.initDatasetsValue, this.datasetsValue)
  }

  notifyForDatasetsLimit() {
    this.dispatch('datasetsLimitReached', {
      detail: { value: this.isAtMaxCapacity() }
    })
  }

  isAtMaxCapacity() {
    return (this.datasetsValue.length + this.lockedDatasetsValue.length) >= this.maxDatasetsValue
  }
}
