import { Controller } from '@hotwired/stimulus'
import { isEqual } from 'lodash';

export default class extends Controller {
  static targets = ['submitButton', 'form', 'checkbox']

  connect() {
    this.initialData = this.#getFormData();

    this.setButtonsState()
  }

  setButtonsState() {
    this.#setSubmitButtonState()
  }

  #formChanged() {
    const currentData = this.#getFormData()

    return !isEqual(this.initialData, currentData)
  }

  #noneChecked() {
    const currentData = this.#getFormData()

    return Object.values(currentData).every((v) => v === false)
  }

  #disableSubmit() {
    return !this.#formChanged() || this.#noneChecked()
  }

  #getFormData() {
    const data = {};

    this.checkboxTargets.forEach((checkbox) => {
      data[checkbox.value] = checkbox.checked;
    })

    return data;
  }

  #setSubmitButtonState() {
    this.submitButtonTarget.classList.toggle('disabled', this.#disableSubmit())
  }
}
