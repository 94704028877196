import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    slideOverId: String
  }

  open(event) {
    event.preventDefault();
    document.getElementById(this.slideOverIdValue).dispatchEvent(new CustomEvent('slide-over:open'))
  }
}
