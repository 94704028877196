import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { rooftopId: Number, threshold: { default: 1, type: Number } }

  connect() {
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.dispatch(
              'dataset_impression',
              {
                detail: {
                  rooftopId: this.rooftopIdValue,
                  threshold: this.thresholdValue,
                },
                bubbles: true,
                prefix: false
              }
            )

            observer.disconnect()
          }
        })
      },
      {
        threshold: this.thresholdValue
      }
    )

    this.observer.observe(this.element)
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
