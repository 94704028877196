import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.resize()
    window.addEventListener('resize', () => this.resize())

    window.addEventListener('turbo:before-fetch-request', () => this.resize());
  }

  close() {
    this.element.classList.remove('visible', 'active')
  }

  open() {
    this.element.classList.add('visible')
  }

  resize() {
    if (window.innerWidth > 768) {
      this.open()
    } else {
      this.close()
    }
  }
}
