import { Controller } from '@hotwired/stimulus'
import { debounce } from '../../../../javascript/src/debounce';

export default class extends Controller {
  static values = {
    datasets: Array,
    isAtMaxCapacity: Boolean
  }

  static targets = ['searchInput', 'datasetsList', 'dataset', 'datasetTemplate', 'maxDatasetsMessage', 'searchStream']

  connect() {
    this.initialSuggestions = this.datasetsValue;
    this.debouncedSubmit = debounce(() => this.search(), 600);
    this.searchInputTarget.addEventListener('input', this.debouncedSubmit);
  }

  preventEnterSubmit(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  search() {
    const query = this.searchInputTarget.value.trim();

    if (query.length === 0) {
      this.searchStreamTargets.forEach((item) => item.remove())
      return
    }

    if (query.length < 3) return;

    this.loadResults(query);
  }

  getForm() {
    if (this.searchInputTarget.form) {
      return this.searchInputTarget.form
    }
    return this.searchInputTarget.closest('form')
  }

  disableSearch() {
    this.datasetsListTarget.classList.add('disabled')
    this.searchInputTarget.classList.add('disabled')
  }

  enableSearch() {
    this.datasetsListTarget.classList.remove('disabled')
    this.searchInputTarget.classList.remove('disabled')
  }

  loadResults() {
    this.getForm().requestSubmit()
  }

  datasetsValueChanged() {
    this.renderDatasetsList()
  }

  renderDatasetsList() {
    this.datasetsListTarget.innerHTML = ''
    this.datasetsValue.forEach((dataset) => {
      this.datasetsListTarget.appendChild(
        this.createDatasetElement(dataset)
      )
    })
    this.toggleDatasetsListState()
  }

  createDatasetElement(dataset) {
    let templateContent = this.datasetTemplateTarget.innerHTML;

    templateContent = templateContent.replace(/{{datasetTitle}}/g, dataset.title);
    templateContent = templateContent.replace(/{{dataProvider}}/g, dataset.data_provider_name);
    templateContent = templateContent.replace(/{{datasetId}}/g, dataset.id);

    const element = document.createElement('div')
    element.innerHTML = templateContent
    return element
  }

  addDataset(event) {
    event.preventDefault();

    if (this.isAtMaxCapacityValue) return

    const dataset = this.datasetsValue.find((d) => d.id === event.params.datasetId)

    this.notifyDatasetAdded(dataset)
    this.removeDatasetFromList(dataset)
  }

  restoreDataset(event) {
    const { dataset } = event.detail;

    if (!this.datasetsValue.some((d) => d.id === dataset.id)) {
      this.datasetsValue = [dataset, ...this.datasetsValue];
    }
  }

  searchStreamTargetConnected() {
    this.loadStreamSearchResults()
  }

  loadStreamSearchResults() {
    this.datasetsValue = JSON.parse(this.searchStreamTarget.dataset.content) || []
  }

  searchStreamTargetDisconnected() {
    this.restoreInitialSuggestions()
  }

  restoreInitialSuggestions() {
    this.datasetsValue = this.initialSuggestions
  }

  handleDatasetsLimitChange(event) {
    this.isAtMaxCapacityValue = event.detail.value
  }

  isAtMaxCapacityValueChanged() {
    this.toggleMaxDatasetsMessageVisibility()
    this.toggleDatasetsListState()
  }

  toggleMaxDatasetsMessageVisibility() {
    this.maxDatasetsMessageTarget.classList.toggle('hidden', !this.isAtMaxCapacityValue)
  }

  toggleDatasetsListState() {
    this.searchInputTarget.classList.toggle('disabled', this.isAtMaxCapacityValue)
    this.datasetTargets.forEach((e) => e.classList.toggle('disabled', this.isAtMaxCapacityValue))
    this.datasetsListTarget.classList.toggle('disabled', this.isAtMaxCapacityValue)
  }

  notifyDatasetAdded(dataset) {
    this.dispatch('datasetAdded', { detail: { dataset } })
  }

  removeDatasetFromList(dataset) {
    this.datasetsValue = this.datasetsValue.filter((d) => d.id !== dataset.id)
  }
}
