import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu', 'option', 'selectedOption', 'input', 'preSelected']

  static values = { active: Boolean }

  connect() {
    this.setDefaultInput()
    this.menuVisible = false
    this.outsideClickHandler = this.hideMenu.bind(this)
  }

  setDefaultInput() {
    if (this.preSelectedTarget) {
      const selectedOption = this.optionTargets.find((option) => (
        option.dataset.value === this.preSelectedTarget.dataset.value
      ))

      selectedOption.setAttribute('aria-selected', 'true')
    }
  }

  hideMenu(event) {
    if (!this.element.contains(event.target)) {
      this.closeMenu()
    }
  }

  toggleMenu(event) {
    if (!this.activeValue) { return }

    if (event.target.dataset.actionsTrigger === 'true') {
      this.closeMenu()
      return
    }

    if (this.menuVisible) {
      this.closeMenu()
      return
    }

    this.openMenu()
  }

  clickOutside(event) {
    if (!this.menuVisible || this.element.contains(event.target)) return

    this.closeMenu()
  }

  closeMenu() {
    this.menuTarget.scrollTop = 0
    this.menuVisible = false
    this.setExpanded('false')
    document.removeEventListener('click', this.outsideClickHandler)
  }

  openMenu() {
    this.menuVisible = true
    this.setExpanded('true')
    document.addEventListener('click', this.outsideClickHandler)
  }

  setExpanded(value) {
    this.element.setAttribute('aria-expanded', value)
  }

  selectOption(event) {
    const selectedOption = event.currentTarget

    this.setInputValue(selectedOption.dataset.value)

    this.selectedOptionTarget.innerHTML = selectedOption.innerHTML

    this.highlightSelectedOption(selectedOption)

    this.closeMenu()
  }

  highlightSelectedOption(selectedOption) {
    this.optionTargets.forEach((option) => {
      option.setAttribute('aria-selected', 'false')
    })
    selectedOption.setAttribute('aria-selected', 'true')
  }

  setInputValue(value) {
    this.inputTarget.value = value

    setTimeout(() => {
      this.dispatch('change', { bubbles: true, prefix: false })
    }, 0)
  }
}
