import { Controller } from '@hotwired/stimulus'
import { debounce, uniq, isEqual } from 'lodash'
import getCSRFToken from '../../../../../javascript/src/getCSRFToken';

export default class extends Controller {
  static values = { pendingImpressions: { type: Array, default: [] } }

  #isProcessing = false

  connect() {
    this.processImpressions = debounce(this.sendImpressions.bind(this), 1000)
  }

  handleImpression(event) {
    const { rooftopId } = event.detail
    const location = window.location.pathname

    this.pendingImpressionsValue = uniq(
      [
        ...this.pendingImpressionsValue,
        { location, rooftop_id: rooftopId }
      ]
    )
  }

  async sendImpressions() {
    this.#isProcessing = true

    const impressionsToSend = [...this.pendingImpressionsValue]

    try {
      const response = await fetch('/dataset_impression_events/bulk_create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(),
        },
        body: JSON.stringify({ dataset_impression_events: impressionsToSend }),
      })

      if (!response.ok) throw new Error('Failed to sync impressions')

      this.pendingImpressionsValue = this.pendingImpressionsValue.filter(
        (pending) => !impressionsToSend.some((sent) => isEqual(pending, sent))
      );
    } catch (error) {
      console.error('Error syncing impressions:', error)
    } finally {
      this.#isProcessing = false
    }
  }

  pendingImpressionsValueChanged() {
    if (this.#isProcessing || this.pendingImpressionsValue.length === 0) return

    this.processImpressions()
  }
}
